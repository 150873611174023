<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>项目供应商评价</b></span>
            <div class="flex-center" @click="backPage"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>
        </div>
        <div class="send-info flex flex-direction">
            <span>项目供应商评价</span>
            <div class="info-item flex flex-direction" v-for="(item,index) in lists" :key="index">
                <div class="name">供应商：{{item.enterprise_name}}</div>
                <div class="name margin-top-xs">委托项目：{{item.payment}}</div>
                <div class="remark margin-top-xs">备注：{{item.audit_opinion}}</div>
                <div class="file margin-top-xs">
                    <a v-for="(file, index) in item.files" :key="index" :href="file.filePath | filePath"
                       target="_blank">{{ file.fileName }}</a>
                </div>
               <div class="flex flex-wrap justify-between">
                   <div class="info-item width flex flex-direction" v-for="(evaluation,position) in item.evaluationQuery" :key="position">
                       <div class="name">{{evaluation.evaluation_name}}:</div>
                       <div class="margin-top-xs">是否合格：
                           <el-radio-group v-model="evaluation.status">
                               <el-radio :label="1">合格</el-radio>
                               <el-radio :label="0">不合格</el-radio>
                           </el-radio-group>
                       </div>
                       <div class="flex margin-top-xs">
                           <div class="flex-shrink">备注：</div>
                           <el-input v-model="evaluation.remark" :rows="5" placeholder="请输入" resize="none" type="textarea"></el-input>
                       </div>
                       <el-button class="self-center margin-top" size="small" type="primary" @click="updatePriceComparison_evaluation(evaluation.id,evaluation.status,evaluation.remark)">提交</el-button>
                   </div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
import {webUrl} from "../../tools";

export default {
    name: "evaluation",
    data() {
        return {
            pid: '',
            lists: []
        };
    },
    created() {
        this.pid = this.$route.query.pid
        this.$emit("setIndex", [1, 1]);
        this.init();
    },
    methods: {
        updatePriceComparison_evaluation(id,status,remark) {
            this.$api.apiContent
                .updatePriceComparison_evaluation({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: id,
                    status: status,
                    remark: remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: id,
                            status: status,
                            remark: remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        init() {

            this.$api.apiContent
                .getPriceComparison_evaluation({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.lists = res.data
                })
                .catch(() => {
                    this.lists = [];
                });
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    watch: {},
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .send-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        span {
            color: #000000;
            font-size: 24px;
            margin: 12px;
        }
            .info-item{
                border: 1px solid #e1e1e1;
                padding: 15px;
                margin: 12px;
                &.width{
                    width: 30%;
                }
                .name{
                    font-size: 18px;
                    color: #333333;
                }
                .remark{
                    font-size: 16px;
                    color: #666666;
                }
                .file {
                    display: flex;
                    flex-direction: column;
                    a {
                        font-size: 18px;
                        cursor: pointer;
                        margin-bottom: 8px;
                        display: block;
                        color: #3b77e7;

                        &:hover {
                            font-size: 18px;
                        }
                    }
                }
        }
    }
}
</style>
